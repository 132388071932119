// https://versions.bulma.io/0.7.0/documentation/overview/customize/
@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/functions";

// eigen kleuren defineren
$revprimary: #429dc8;
$revlink: #4287c8;
$revinfo: #5c5d8b;
$revsucces: #4bb39a;
$revwarning: #f08708;

// bulma kleuren aanpassen
$primary: $revprimary;
$link: $revlink;
$info: $revinfo;
$succes: $revsucces;
$warning: $revwarning;

//eigen text sizes definieren
$revtitle-size: 2rem; //geldt voor h1 titels
$revsubtitle-size: 1.5rem; //geldt voor h2 tm h4 subtitles
$revbody-size: 1rem; //paragraaf teksten
$revsize-7: 0.6rem;

//bulma text sizes aanpassen
$title-size: $revtitle-size;
$subtitle-size: $revsubtitle-size;
$body-size: $revbody-size;
$size-7: $revsize-7;

// import nodig om te updated
@import "bulma/bulma";


