
/************ In dit css komen de aanpassing voor react componenten *************/





/************ voor het zoek selectie veld *************/
.rev-custom-component-expandaddon>.rev-search-select{
    padding-left: 0px;
    padding-right: 0px;
  }
  
  .rev-custom-component-expandaddon>.rev-search-select>div {
    width: 100%;
    position: relative;
  }
/************ einde selectie veld *************/