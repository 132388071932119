@use './rev-shared-media-size.scss' as sizes;
@use './rev-color-overrides.scss' as colors;

.rev-modal {
  /* display: none; */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(255, 255, 255, 0.5);
}

.rev-modal-content {
  background-color: #fefefe;
  z-index: 1001;
  vertical-align: central;
  margin: auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  opacity: 1;
  position: relative;
}

.rev-modal-content-fullscreen {
  background-color: #fefefe;
  z-index: 1001;
  vertical-align: central;
  margin: auto; /* 15% from the top and centered */
  border: 1px solid #888;
  opacity: 1;
  position: relative;
  width: 100%;
  top: 0px;
  height: 100%;
  overflow: auto;
}

.rev-modal-content-half {
  width: 50%;
  margin: auto;
}

@media (min-width: sizes.$tablet) {
  .rev-modal-content {
    top: 50%;
    width: 50%; /* Could be more or less, depending on screen size */
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media (max-width: sizes.$tablet) {
  .rev-modal-content-half {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: sizes.$smaller-tablet) {
  .rev-modal-content {
    width: 75%;
  }
}

@media (max-width: sizes.$tablet) {
  .rev-modal-content {
    width: 100%;
    top: 0px;
    height: 0px;
  }
}

@media (min-width: sizes.$tablet) and (max-width: sizes.$tablet-max) {
  .rev-modal-content-half {
    width: 80%;
    margin: auto;
  }
}
