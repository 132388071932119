
.rev-menu-open{
    -webkit-transition: max-height 0.5s ease-in-out; 
    -moz-transition: max-height 0.5s ease-in-out; 
    -ms-transition: max-height 0.5s ease-in-out; 
    -o-transition: max-height 0.5s ease-in-out; 
    transition: max-height 0.5s ease-in-out;  
    overflow: hidden;
}

.rev-right-slide{
    -webkit-transition: right 0.5s ease-in-out; 
    -moz-transition: right 0.5s ease-in-out; 
    -ms-transition: right 0.5s ease-in-out; 
    -o-transition: right 0.5s ease-in-out; 
    transition: right 0.5s ease-in-out;  
    
}

.rev-menu-open-mobile{
    -webkit-transition: max-height 0.5s ease-in-out; 
    -moz-transition: max-height 0.5s ease-in-out; 
    -ms-transition: max-height 0.5s ease-in-out; 
    -o-transition: max-height 0.5s ease-in-out; 
    transition: max-height 0.5s ease-in-out;  
    overflow: hidden;
}