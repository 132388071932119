@use './rev-shared-media-size.scss' as sizes;
@use './rev-color-overrides.scss' as colors;

.rev-project-new-arrow {
  position: fixed;
  top: 50%;
  height: 100%;
  max-width: 40px;
  overflow: visible;
  opacity: 0.5;
  z-index: 10;

  &-left {
    left: 30px;
  }

  &-right {
    right: 30px;
  }
}

.rev-project-new-arrow:hover {
  opacity: 1;
  z-index: 2;
}

.rev-project-new-fa {
  -webkit-transform: scale(4, 4);
  -moz-transform: scale(4, 4);
  -ms-transform: scale(4, 4);
  -o-transform: scale(4, 4);
  transform: scale(4, 4);
}

.rev-project-readonly-input {
  background-color: transparent;
  border: 1px;
  border-color: hsl(0deg, 0%, 71%);
  border-style: solid;
}

.rev-project-radio {
  margin-top: 8px;
}

.rev-project-input {
  max-width: 80%;
}
.rev-project-input > .select {
  display: block;
}

.field-label {
  flex-grow: 2;
}

@media screen and (max-width: sizes.$tablet-max) {
  .rev-project-input {
    max-width: 90%;
  }
}

@media screen and (min-width: sizes.$desktop-large) {
  .field-label {
    flex-grow: 1;
  }
}

.rev-project-box-internal-padding {
  margin: 15px 5px;
}

.rev-project-box-child-margin > * {
  margin: 10px 0 20px 0;
}

.rev-project-center {
  flex-grow: 1;
}

.rev-round {
  border-radius: 50%;
}

.rev-floating-button {
  position: fixed;
  margin: 10px;
  z-index: 99;

  &-small {
    opacity: 0.5;
    width: 50px;
    height: 50px;
  }

  &-80x80 {
    width: 80px;
    height: 80px;
  }

  &-topleft {
    left: 0.5em;
    top: 0.5em;
  }
  &-topright {
    right: 1.5em;
    top: 1.5em;
  }
  &-bottomright {
    right: 1.5em;
    bottom: 4em;
  }
  &-bottomleft {
    left: 1.5em;
    bottom: 1.5em;
  }
}

@media (max-width: sizes.$tablet) {
  .rev-floating-button {
    // &-topleft {
    //   //opacity: 0.5;
    // }
    &-topright {
      right: 0.5em;
      top: 0.5em;
    }
    &-bottomright {
      right: 0.1em;
      bottom: 4em;
    }
    &-bottomleft {
      left: 0.5em;
      bottom: 0.5em;
    }
  }
}

.rev-responsive {
  &-background {
    position: fixed;
    bottom: 0px;
    left: 0;
    margin: auto;
    padding: 10px 50px;
    width: 100%;
    z-index: 99;

    &-top {
      top: 0px;
    }
  }
  &-opaque-background {
    box-shadow: 0 0 3px;
    background-color: white;
    opacity: 1;
  }
}

.rev-responsive-slide-back-down {
  animation-name: slide-back-down;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.rev-responsive-slide-up {
  animation-name: slide-up;
  animation-duration: 0.5s;
}

//https://www.w3schools.com/css/tryit.asp?filename=trycss3_animation3
@keyframes slide-up {
  0% {
    bottom: -100px;
    opacity: 0;
  }
  100% {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes slide-back-down {
  0% {
    bottom: 0px;
    opacity: 1;
  }
  100% {
    bottom: -100px;
    opacity: 0;
  }
}

@media screen and (max-width: sizes.$tablet) {
  .rev-project-input {
    max-width: 100%;
  }
}

@media screen and (max-width: sizes.$tablet-max) {
  .rev-responsive-background {
    padding: 10px;
  }
}

@media screen and (max-width: sizes.$tablet) {
  .rev-project-button {
    font-size: 0.8em;
  }
}

.rev-project-button.is-active {
  background-color: #429dc8;
  border-color: #429dc8;
}

@media screen and (max-width: sizes.$tablet-max) {
  #rev-project-menu {
    top: 65px !important;
  }
}
