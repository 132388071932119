@use './rev-color-overrides.scss' as colors;
@use 'bulma/sass/utilities/mixins.sass' as mixins;
.bulma-loader-mixin {
  @include mixins.loader;
}

.loader-center-modal {
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent
}

.loader-center-modal .loader{
  padding: 30px;
  border-color: orange Orange transparent transparent;
  border-width: 4px;
}

.primary-form-loader{
  
  background-color: colors.$projectPrimaryBackground;
}

.primary-form-loader .loader{
  border-top-color:colors.$mjop;
  border-width: 4px;
  margin: auto;
  font-size: 2.5em;
}

