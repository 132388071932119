// media
$tablet: 768px;
$tablet-max: 1023px;
$desktop: 1024px;
$desktop-large: 1216px;

// texts
$smaller-tablet: 992px;

// project
$project-mobile: 600px;

// bouwgebreken
$bouwgebrek-tabs: 927px;


$scroll-padding-top: 96px;

$scroll-padding-top-touch:52px;