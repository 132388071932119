
// hide tabbed page radiobutton selection
input[name="rev-membership-radiobutton"] ,
input[name="rev-tabbedpage-radiobutton"] {
    display: none;
}

/********************* HOME PAGE memberships.js ****************************/

// membership shizzle
#tab-basis:not(:checked) ~ .rev-membership-content > #content-basis,
#tab-business:not(:checked) ~ .rev-membership-content > #content-business,
#tab-pro:not(:checked) ~ .rev-membership-content > #content-pro {
  height:0px;
  width: 0px;
    visibility: hidden;
    overflow: hidden;
    padding: 0;
    margin: 0;
 //   font-size: 0%;
    opacity:0;
    * {
      opacity:0;
    //  font-size: 0%;
      padding: 0;
      margin: 0;
    }
}

#tab-basis:checked ~ .rev-membeship-tabs label[for='tab-basis'],
#tab-business:checked ~ .rev-membeship-tabs label[for='tab-business'],
#tab-pro:checked ~ .rev-membeship-tabs label[for='tab-pro']{
  *{
    background-color: #fff;
    color: #4287c8;
    border-color: #dbdbdb;
    border-bottom-color: rgb(219, 219, 219);
    border-bottom-color: transparent !important;
  }

}


#content-basis,
#content-business,
#content-pro {
  
  transition: all 0.4s ease;
  * {
      transition: all 0.4s ease;
    }
  }



/********************* TABBED ARTICLE articleTabbed.js ****************************/

input[id='plaats van het gebrek']:not(:checked) ~ section > div> div>  div[id='content-plaats van het gebrek'],
#kenmerken:not(:checked) ~ section > div> div>  #content-kenmerken,
#oorzaken:not(:checked) ~ section > div> div>  #content-oorzaken,
#herstel:not(:checked) ~ section > div> div>  #content-herstel,
#preventie:not(:checked) ~ section > div> div>  #content-preventie,
#herstelkosten:not(:checked) ~ section > div> div>  #content-herstelkosten,
#werkwijze:not(:checked) ~ section > div> div>  #content-werkwijze,
#kosten:not(:checked) ~ section > div> div>  #content-kosten {
  height:0px;
  width: 0px;
    visibility: hidden;
    overflow: hidden;
    padding: 0;
    margin: 0;
 //   font-size: 0%;
    opacity:0;
    * {
      opacity:0;
    //  font-size: 0%;
      padding: 0;
      margin: 0;
    }
}


// tab backgrounds
#kenmerken:checked ~ .rev-tabbed-nav label[for='kenmerken'],
#kenmerken:checked ~ section label[for='kenmerken'],
#oorzaken:checked ~ .rev-tabbed-nav  label[for='oorzaken'],
#oorzaken:checked ~ section  label[for='oorzaken'],
#herstel:checked ~ .rev-tabbed-nav label[for='herstel'],
#herstel:checked ~ section label[for='herstel'],
#preventie:checked ~ .rev-tabbed-nav  label[for='preventie'],
#preventie:checked ~ section  label[for='preventie'],
#herstelkosten:checked ~ .rev-tabbed-nav  label[for='herstelkosten'],
#herstelkosten:checked ~ section  label[for='herstelkosten'],
#werkwijze:checked ~ .rev-tabbed-nav  label[for='werkwijze'],
#werkwijze:checked ~ section label[for='werkwijze'],
#kosten:checked ~ section label[for='kosten'],
#kosten:checked ~ .rev-tabbed-nav  label[for='kosten'],
input[id='plaats van het gebrek']:checked ~ section label[for='plaats van het gebrek'],
input[id='plaats van het gebrek']:checked ~ .rev-tabbed-nav label[for='plaats van het gebrek']{
  min-height: 200px;
  background-color: #4287c8;
  border-color: #4287c8;
  color: #fff;
  z-index: 1;
  *{
    background-color: #4287c8;
    border-color: #4287c8;
    color: #fff;
    z-index: 1;
  }

}


input[id='plaats van het gebrek'] ~ section > div> div>  div[id='content-plaats van het gebrek'],
#kenmerken ~ section > div> div> #content-kenmerken,
#oorzaken ~ section > div> div>  #content-oorzaken,
#herstel ~ section > div> div>  #content-herstel,
#preventie ~ section > div> div>  #content-preventie,
#herstelkosten ~ section > div> div>  #content-herstelkosten,
#werkwijze ~ section > div> div>  #content-werkwijze,
#kosten ~ section > div> div>  #content-kosten {
  
    transition: all 0.6s ease;
    * {
      transition: all 0.6s ease;
    }
  
}
