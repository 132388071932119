/* REV on hover*/

/* REV on information/hint */

.rev-information {
  padding-left: 10px;
  padding-right: 10px;
  font-size: medium;
  font-style: italic;
  font-family: "Times New Roman";
  font-weight: bolder;
  cursor: pointer;
}

.rev-information-small {
  font-size: small;
  padding-left: 5px;
  padding-right: 5px;
}

.rev-information-circle {
  color: black;
  background-color: #ecf6ff;
  border-color: #afafaf;
  border-style: solid;
  border-radius: 50%;
  border-width: 1px;
}

.rev-tooltip-underline {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.rev-tooltip {
  overflow: visible;
}

.rev-tooltip .rev-tooltiptext {
  background-color: white;
  font-weight: normal;
  display: none;
  width: fit-content;
  max-width: 200px;
  border: solid;
  border-width: 1px;
  border-color: #050505;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  text-align: left;
  padding: 5px 5px;
  border-radius: 4px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 100;
}

.rev-tooltip .rev-tooltiptext-center {
  margin-left: 100px;
}

.rev-tooltip .rev-tooltiptext-left {
  margin-left: -200px;
}

.rev-tooltip:hover .rev-tooltip-bottomup {
  bottom: 0px;
}

.rev-tooltip:hover .rev-tooltiptext {
  display: inherit;
}

/* small screens*/
@media (max-width: 992px) {
}

/* tablet size or smaller screens*/
@media (max-width: 767px) {
  .rev-tooltip .rev-tooltip-mobile-show {
    display: block;
  }
}
