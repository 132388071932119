/* overrides van bulma. Dit zou in de kleuren schema gecorrigeerd moeten worden. */

$revlinkhover: #f08708;
$revlinactive: #f08708;
$shopColor2024: #646c56;
$disableColor: #e2e2e2;

/* voor de breadcrumb */
.navbar-dropdown a.navbar-item:active,
.navbar-dropdown a.navbar-item:focus,
a.navbar-item:focus,
.breadcrumb li.is-active a {
  color: $revlinactive;
}

.breadcrumb a:hover,
.navbar-dropdown a.navbar-item:hover,
.navbar-link:hover,
a.navbar-item:hover,
a:hover {
  color: $revlinkhover;
}

// vtw color scheme
$woningen: #fbaf5c;
$garages: #26caed;
$kantoren: #6dd2a6;
$bedrijfsgebouwen: #e1de59;
$winkels: #ff8588;
$herbestemming: #bb4a44;
$argrarish: #b3d986;
$overig: #e1a8dd;
$mav1: #d8d46d;
$mav2: #d63b83;
$mav3: #61b3da;

// project color scheme
$kenmerken: #f3cb8e;
$onderhoudskosten: #f3cb8e;
$rapportage: #f3cb8e;
$mjop: #f3cb8e;
$back: #f3cb8e;

$projectPrimary: #4f87a3;
$projectPrimaryBackground: #efefef;
$projectSecondary: #7cafd1;
$projectSecondaryBackground: #f0f0f0;
$projectTeritary: #89a3b5;
$projectTeritaryBackground: #fafafa;

.rev-highlight-notfound {
  background-color: #f08708;
  border: 1px solid #f08708;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.rev-highlight-woningen {
  background-color: $woningen;
  border: 1px solid $woningen;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.rev-highlight-winkels {
  background-color: $winkels;
  border: 1px solid $winkels;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.rev-highlight-herbestemming {
  background-color: $herbestemming;
  border: 1px solid $herbestemming;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.rev-highlight-argrarish {
  background-color: $argrarish;
  border: 1px solid $argrarish;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.rev-highlight-overig {
  background-color: $overig;
  border: 1px solid $overig;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.rev-highlight-mav-1 {
  background-color: $mav1;
  border: 1px solid $mav1;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.rev-highlight-mav-2 {
  background-color: $mav2;
  border: 1px solid $mav2;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.rev-highlight-mav-3 {
  background-color: $mav3;
  border: 1px solid $mav3;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.rev-highlight-garages {
  background-color: $garages;
  border: 1px solid $garages;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.rev-highlight-kantoren {
  background-color: $kantoren;
  border: 1px solid $kantoren;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

.rev-highlight-bedrijfsgebouwen {
  background-color: $bedrijfsgebouwen;
  border: 1px solid $bedrijfsgebouwen;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: white;
}

// project list
.rev-highlight-edit {
  color: $kantoren;
}

.rev-highlight-copy {
  color: orange;
}

.rev-highlight-delete {
  color: red;
}

.rev-highlight-edited {
  background-color: beige;
}

.rev-highlight-kenmerken {
  background-color: $kenmerken;
}

.rev-highlight-onderhoudskosten {
  background-color: $onderhoudskosten;
}

.rev-highlight-rapportage {
  background-color: $rapportage;
}

.rev-highlight-mjop {
  background-color: $mjop;
}

.rev-highlight-back {
  background-color: $back;
}

// .rev-table-projectlist{
//     background-color: ;
// }
