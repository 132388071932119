@use './rev-shared-media-size.scss' as sizes;

@media screen and (max-width: 768px) {
  .rev-text-center-on-touch > * {
    text-align: center;
    display: block;
  }
}

//h1 is-3
.content h1 {
  font-size: 2rem;
}

//h2 is-4
.content h2 {
  font-size: 1.5rem;
}

//h3 is-5
.content h3 {
  font-size: 1.25rem;
}

//h4 is-6
.content h4 {
  font-size: 1rem;
}

@media screen and (max-width: sizes.$tablet) {
  .is-size-8-mobile {
    font-size: 0.65rem;
  }
}
@media screen and (max-width: sizes.$tablet-max) {
  h2 {
    font-size: 24px;
  }
}
@media screen and (max-width: sizes.$tablet-max) {
  .content p {
    font-size: 1rem;
  }
}
