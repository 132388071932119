@use './rev-shared-media-size.scss' as sizes;

.rev-lesson-article {
  //margin: 10px;
  padding-bottom: 25px;
  padding-top: 25px;
  position: relative;
}

.rev-side {
  padding-top: 200px;
  vertical-align: middle;
  position: fixed;
  z-index: 10;
  right: 0px;
  width: 300px;

  &-main {
    bottom: 0px;
    top: 0px;
    background-color: #f5f5f5;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.25);
  }
  &-pageholder {
    background-color: transparent;
  }
}

@media (max-width: sizes.$tablet) {
  .rev-side {
    padding-top: 96px;
  }
}

.rev-side-page-hidden {
  right: -300px;
}

.rev-side-page-pageholder {
  padding: 10px;
  color: white;
  background-color: #f39300;
  position: absolute;
  left: -80px;
  /*bottom:25%;*/
  border-radius: 10px 0px 0px 10px;
}

.rev-side-page-pageholder:hover {
  background-color: #33346e;
  color: white;
}

@media screen and (min-width: sizes.$desktop) {
  .rev-side-page-pageholder {
    left: -70px;
  }
}

.rev-side-article {
  margin: 10px;
}

.rev-side-article-divider {
  background-color: #cfcfcf;
  margin-top: 10px;
  margin-bottom: 10px;
}
