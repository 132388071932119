/* overrides (no longer needed) */
/* .content table td,
.table td, 
.table th
.table thead th
.content .table thead th
{
    border: 0px;
    padding:2px;
} */

.rev-table-center {
  font-size: small;
  margin-left: auto;
  margin-right: auto;
}


.table-no-border > tbody > tr > td{
  padding:0px;
  border: none;
}

