@use './rev-shared-media-size.scss' as sizes;

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.navbar-brand> .navbar-item > img {
  max-height: 5rem;
}



.rev-menu-toggle-checkbox{
    display: none;
}


/* touch menu */
@media screen and (max-width: sizes.$tablet-max) {

#revtogglemenu {
  display: block;
  transition: all 0.6s ease;
  * {
    transition: all 0.6s ease;
  }
}

#navbar-burger-toggle:not(:checked) ~ #revtogglemenu {

  line-height: 0;
  visibility: hidden;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border-width: 0;

  *{
    border-width: 0;
  }

.button {
  height: 0px;
}
}

#navbar-burger-toggle:checked +div > label > span:nth-child(1){
    transform: translateY(5px) rotate(45deg);
    top: calc(50% - 6px)
}

#navbar-burger-toggle:checked + div >label > span:nth-child(2){
    opacity:0;
    top: calc(50% - 1px)
}

#navbar-burger-toggle:checked + div >label > span:nth-child(3){
    transform: translateY(-5px) rotate(-45deg);
    top: calc(50% + 4px)
}

.rev-menu-toggle-checkbox + .has-dropdown > .navbar-dropdown{
  
  max-height:max;
  transition: all 0.6s ease;
  * {
    transition: all 0.6s ease;
  }
}

input:not(:checked) + div + .navbar-menu,
.rev-menu-toggle-checkbox:not(:checked) + .has-dropdown > .navbar-dropdown{
  

  line-height: 0;
  
  visibility: hidden;
  overflow: hidden;
  padding: 0;
  margin: 0;
  //font-size: 0%;
  opacity:0;
  * {
    overflow: hidden;
    opacity:0;
    line-height: 0;
    //font-size: 0%;
    padding: 0;
    margin: 0;
  }
}




  .navbar-brand> .navbar-item > img {
    max-height: 2.25rem;
  }
  

  html {
    font-size: 20px;
  }

  .rev-hidden-on-touch{
    max-height: 0px !important;
    overflow: hidden;
  }

  .navbar-dropdown{
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.topbar{
  left:0px;
  right: 0px;
  top:0px;
  z-index: 30;
}

.topbar.is-fixed-top,
.navbar.is-fixed-top {
  /* this will make the navbar float on the top without hiding stuff beneath it */
  position: sticky !important;
  
}



.navbar-filler{
  height: 5rem;
  width: 100%;
  
}


.navbar{
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}




