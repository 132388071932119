@use './rev-shared-media-size.scss' as sizes;
@use './rev-color-overrides.scss' as colors;

html {
  overflow: hidden;
}

#layout-scroll {
  scroll-padding-top: sizes.$scroll-padding-top;
  scroll-behavior: smooth;
}

body,
#root {
  height: 100vh;
  overflow-y: hidden;
}

#root::-webkit-scrollbar {
  display: none;
}


.everyother-row-blue tr:nth-child(even){
  background-color: #eff7fa;
}


.rev-breadcrumb {
  white-space: normal;
}

.rev-image img {
  width: 600px;
  border-radius: 10px;
  max-height: 300px;
  object-fit: contain;
}
.rev-cookie-delete {
  right: 0.5rem;
  position: absolute;
  top: 0.5rem;
}

@media screen and (max-width: sizes.$bouwgebrek-tabs) {
  #rev-tabs ul {
    flex-direction: column;
    border-radius: none;
  }
}

.rev-modal-opacity {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.rev-full-width {
  width: 100%;
}

/* flex implementatie van projecten zoek lijst */

.rev-flex-container {
  /*background-color: #eee;*/
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
@media (max-width: sizes.$smaller-tablet) {
  .rev-flex-container {
    justify-content: center;
  }
}

.rev-flex-container > .rev-flex-item {
  flex: 1;
  // padding: 2px;
  min-width: 300px;
  //min-height: 500px;
  max-width: 400px;

  margin-left: auto;
  margin-right: auto;
}

.rev-mb-small {
  * {
    margin-bottom: 0.25rem;
  }
}

@media (max-width: sizes.$smaller-tablet) {
  .rev-flex-container > .rev-flex-item {
    font-size: smaller;
  }
}

@media (max-width: sizes.$tablet-max) {
  html {
    scroll-padding-top: sizes.$scroll-padding-top-touch;
  }
}

@media (max-width: sizes.$tablet) {
  .rev-flex-container > .rev-flex-item {
    font-size: x-small;
  }
}

.transition {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
}

.slide-in {
  background: transparent;
  transform-origin: center;
  height: 100vh;
}

.slide-out {
  // background: #0f0f0f;
  transform-origin: center;
}

.rev-image-semiround {
  border-radius: 10px;
}

.rev-image-limiter {
  max-width: 640px;
  max-height: 200px;
  margin-top: 10px;
}

@media (max-width: sizes.$project-mobile) {
  .project-box-width-screen-size-dependent {
    width: 50%;
  }

  .project-box-float-left > .project-box-width-screen-size-dependent {
    width: 50%;
  }
}
/* 
@media (max-width:400px) {

} */

.rev-boxes .box {
  border-radius: 20px;
  margin: 5px;
}
.rev-flex-item.box.is-shadowless {
  background-color: hsl(0, 0%, 96%);
  padding: 0px;
}

.rev-hide-overflow,
.rev-boxes-left .box {
  overflow-x: clip;
}

.rev-boxes .box .checkbox:hover {
  background-color: lightblue;
}

.rev-img-fit {
  object-fit: cover;
  height: 200px;
  width: 100%;
}

/* .footer{
  z-index: 10;
} */

.rev-authorized-content {
  opacity: 0.4;
}

.rev-table-projectlist {
  font-size: smaller;
}

/*.rev-table-newproject {
  font-size: small;
}*/

.has-addons .rev-project-expandaddon {
  width: 100%;
}

.rev-title-newproject {
  font-size: large;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.image .rev-image-contain {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/*bouwgebreken kleine afbeelding*/
.image .rev-image-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/************ project pagina uitklap doos *************/
.rev-folding-box .rev-box-title {
  background-color: colors.$projectPrimary;
  border-radius: 4px;
  color: white;
  font-size: larger;
  padding: 5px;
  height: 100%;
}
.rev-folding-box .rev-box-title:hover {
  background: radial-gradient(
    circle,
    rgba(99, 170, 203, 1) 0%,
    rgb(45, 148, 196) 100%
  );
  cursor: pointer;
}

.rev-folding-box {
  background-color: colors.$projectPrimaryBackground;
}

.rev-folding-box-secondary .rev-box-title {
  background-color: colors.$projectSecondary;
  color: white;
  font-size: larger;
  padding: 5px;
  height: 100%;
}

.rev-folding-box-secondary {
  background-color: colors.$projectSecondaryBackground;
}

.rev-folding-box-tertiary .rev-box-title {
  background-color: colors.$projectTeritary;
  color: white;
  font-size: larger;
  padding: 5px;
  height: 100%;
}

.rev-folding-box-tertiary {
  background-color: colors.$projectTeritaryBackground;
}

/************ project pagina uitklap doos einde *************/

.rev-btn-inschrijf {
  color: white;
  background-color: #f08708;
  border: none;
}

.rev-btn-inschrijf:hover {
  color: white;
  background-color: #33346e;
}

.rev-is-borderless {
  border: 0px !important;
}
.rev-is-compact {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.rev-header-image {
  background-image: url("../../public/images/1920px-rev-vogelvlucht-web.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgb(100 108 86 / 75%);
}

#header-slider .hero-body {
  height: 500px;
}

.rev-header-slider {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: stretch;
  width: 100%;

  &-1 {
    background-image: url("../../public/images/1920px-rev-vogelvlucht-web.jpg");
    box-shadow: inset 0 0 0 2000px rgb(100 108 86 / 50%);
  }

  &-2 {
    background-image: url("../../public/images/1920px-vaoshop-web.jpg");
    box-shadow: inset 0 0 0 2000px rgb(100 108 86 / 75%);
  }
  //box-shadow: inset 0 0 0 2000px rgba(43, 43, 43, 0.3);
}

.revlogoimg {
  width: 80%;
  max-height: 100px;
  object-fit: contain;
}

.rev-gif {
  width: 400px;
}

@media (max-width: sizes.$smaller-tablet) {
  .rev-gif {
    width: 75%;
    max-width: none;
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

a.rev-topmenu {
  color: black;
}

.rev-powered-text {
  color: #bda584;
  font-size: 0.9rem;
}

.slider-pagination .slider-page {
  background-color: #d7d7d7;
}

.slider-navigation-next,
.slider-navigation-previous {
  display: none;
}
